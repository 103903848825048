<template>
  <div class="mainform">
    <div class="mainHeader">审核评分
        <span @click="back" class="back">返回></span>
    </div>
    <div class="qualityBtn" style="marginTop:20px; margin-left:20px">
       <el-button
         type="primary"
         size="medium"
         plain
         @click="dialogShow1"
         >+选择评分项</el-button
       >
    </div>
    <div class="form">
        <el-form class="content bascform" ref="form" :model="form" label-width="100px">
        <div class="col col4">
            <el-form-item label="供应商编码" prop="supplierCode">
                <span class="onlyText">
                {{form.supplierCode}}
                </span>
            </el-form-item>
        </div>
        <div class="col col4">
            <el-form-item label="供应商名称" prop="supplierName">
                <span class="onlyText">
                {{form.supplierName}}
                </span>
            </el-form-item>
        </div>
        <div class="col col4">
            <el-form-item label="审核年度" prop="auditYear">
                <span class="onlyText">
                {{form.auditYear}}
                </span>
            </el-form-item>
        </div>
        <div class="col col4">
            <el-form-item label="创建时间" prop="createDate">
                <span class="onlyText">
                {{form.createDate}}
                </span>
            </el-form-item>
        </div>
        <div class="col col4">
            <el-form-item label="审核说明" prop="auditDesc">
              <span class="onlyText">
                {{form.auditDesc}}
              </span>
            </el-form-item>
        </div>
        </el-form>
    </div>
    <div class="tableList">
      <div class="table">
          <el-table :data="tableData" ref="tabledeatailData">
              <el-table-column prop="serialNumber" label="序号"  :show-overflow-tooltip="true"></el-table-column>
              <el-table-column prop="name" label="评分项"  :show-overflow-tooltip="true"></el-table-column>
              <el-table-column prop="scoreNum" label="得分"  :show-overflow-tooltip="true"></el-table-column>
              <el-table-column prop="scoreDetailList" label="评分细项"  :show-overflow-tooltip="true">
                  <template #default="scope">
                    <el-button  type="text" size="mini" @click="scoreDetailBtn(scope.row)">评分细项</el-button>
                  </template>
              </el-table-column>
              <el-table-column prop="type" label="备注"  :show-overflow-tooltip="true">
                  <template #default="scope">
                    <el-input  v-model="scope.row.bz"></el-input>
                  </template>
              </el-table-column>
          </el-table>
      </div>
    </div>
    <div class="action">
        <el-button  type="primary" size="medium" @click="saveBtn">确认</el-button>
    </div>

    <el-dialog title="选择评分项" v-model="dialogShow_1">
      <div>
        <el-table
          ref="multipleTable"
          :data="pingfenlist"
          tooltip-effect="dark"
          style="width: 100%"
          @selection-change="handleSelectionChange">
          <el-table-column
            type="selection"
            width="55">
          </el-table-column>
          <el-table-column
            prop="name"
            label="评分项">
          </el-table-column>
          <el-table-column
            prop="trueScore"
            label="基本分">
          </el-table-column>
        </el-table>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogShow_1 = false">取 消</el-button>
          <el-button type="primary" @click="ping_queding_click"
            >确 定</el-button
          >
        </span>
      </template>
    </el-dialog>

    <el-dialog title="评分细项及打分" v-model="DialogShow">
      <div>
        <el-table
          ref="Tableccc"
          :data="pingfenlist_xi"
          tooltip-effect="dark"
          style="width: 100%"
          @selection-change="handleSelectionChange">
          <el-table-column
            prop="detailName"
            label="评分细项"
            width="120">
          </el-table-column>
          <el-table-column
            prop="detailTrueScore"
            label="总分"
            width="120">
          </el-table-column>
          <el-table-column
            prop="trueScore"
            label="打分"
            width="100">
            <template #default="scope">
              <input @click="chuccccc(scope.row)" type="text" v-model="scope.row.scoreNum" style="width:60px" class="shu_dafen">
            </template>
          </el-table-column>
          <el-table-column
            prop="trueScore"
            label="备注"
            width="200">
            <template #default="scope">
              <input class="shu_dafen" v-model="scope.row.bz" type="text">
            </template>
          </el-table-column>
        </el-table>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="DialogShow = false">取 消</el-button>
          <el-button type="primary" @click="DialogShow_click"
            >确 定</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import Component from '@/assets/js/components.js'
import { request } from '@/assets/js/http.js'
export default {
  components: { ...Component.components },
  name: 'CheckScoreDetail',
  data () {
    return {
      DialogShow: false,
      dialogShow_1: false,
      showFormData: {},
      tableData: [],
      onClickId: '',
      form: {},
      pingfenlist: [], // 所有评分项
      pingfenlist_xi: [], // 评分项细项
      id: '',
      obtainData: [] // 单选的评分项
    }
  },
  mounted () {
    this.auditScoreList()
  },
  methods: {
    chuccccc (data) {
      console.log(data)
    },
    // 选择评分项
    handleSelectionChange (row, selection) {
      // 判断长度等于1 获取
      if (row.length === 1) {
        this.obtainData = row
      }
      this.obtainData = row
      // if (row.length > 1) {
      // const a = row[1]
      // const aa = []
      // aa.push(a)
      // this.obtainData = aa
      // shift() 方法用于把数组的第一个元素从其中删除，并返回第一个元素的值。
      // 该方法不创建新数组，而是直接修改原有的 arrayObject。
      // const cc = row.shift()
      // 其余的都不选中
      // this.$nextTick(() => {
      // row.forEach((item) => {
      // if (item.id === row[0].id) {
      // console.log(item)
      // this.$refs.multipleTable.toggleRowSelection(item, false)
      // }
      // })
      // })
      // }
      console.log(this.obtainData)
    },
    // 评分选择时点确定
    ping_queding_click () {
      this.dialogShow_1 = false
      this.tableData = this.obtainData
    },
    dialogShow1 () {
      // 选择评分项按钮
      this.dialogShow_1 = true
      const bbb = {
        status: 1,
        pageNum: 1,
        pageSize: 9999
      }
      console.log(bbb)
      request('/api/quality/score/list', 'get', bbb).then((res) => {
        console.log(res)
        this.pingfenlist = res.data.records
        // if (Response.code === '200') {
        //   this.DialogShow = false
        //   this.auditScoreList()
        // }
      })
    },
    // 评分细项及打分点击确定
    DialogShow_click () {
      for (let i = 0; i < this.tableData.length; i++) {
        if (this.tableData[i].id === this.onClickId) {
          // console.log(this.pingfenlist_xi)
          let zhong = 0
          for (let i = 0; i < this.pingfenlist_xi.length; i++) {
            zhong = (this.pingfenlist_xi[i].scoreNum) * 1 + (zhong * 1)
          }
          this.tableData[i].scoreNum = zhong
          this.tableData[i].scoreDetailList = JSON.parse(JSON.stringify(this.pingfenlist_xi))
          // this.$set(this.tableData[0], 'company', zhong)
          this.DialogShow = false
        }
      }
    },
    buttonClick: function (data) {
      this.DialogShow = true
    },
    saveBtn () {
      const obj = this.form
      console.log(obj)
      console.log(this.tableData)
      obj.scoreList = this.tableData
      let zhong = 0
      for (let i = 0; i < obj.scoreList.length; i++) {
        zhong = (obj.scoreList[i].scoreNum) * 1 + (zhong * 1)
      }
      obj.scoreNum = zhong
      // obj.scoreList[0].scoreDetailList = this.pingfenlist_xi
      console.log(obj)
      request('/api/quality/auditScore/save', 'post', obj).then((Response) => {
        console.log(Response)
        if (Response.code === '200') {
          this.$message({
            message: '确认成功',
            type: 'success'
          })
          this.DialogShow = false
          // this.auditScoreList()
          location.reload()
        }
      })
    },
    scoreDetailBtn (data) { // 评分细项
      console.log(data)
      this.onClickId = data.id
      request(`/api/quality/scoreDetail/getByScoreId/${data.id}?status=1`, 'get').then((res) => {
        // console.log(res)
        if (res.code === '200') {
          const ccc = res.data
          console.log(ccc)
          console.log(this.obtainData)
          for (const row of data.scoreDetailList) {
            for (const row1 of ccc) {
              if (row.id === row1.id) {
                row1.scoreNum = row.scoreNum
                row1.bz = row.bz
              }
            }
          }
          this.pingfenlist_xi = ccc
        }
      })
      this.DialogShow = true
    },
    handleClose () {
      this.DialogShow = false
    },
    confirmAdd () {
      this.DialogShow = false
    },
    auditScoreList () {
      this.id = this.$route.query.id
      request('/api/quality/auditScore/getById/' + this.id, 'post').then((res) => {
        console.log(res)
        if (res.code === '200') {
          this.tableData = res.data.scoreList
          this.form = res.data
          console.log(this.pingfenlist_xi)
        }
      })
    },
    back: () => {
      window.history.back()
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/css/tableList.scss';
@import '@/assets/css/elForm.scss';
.mainform {
    .form{
       :deep(.el-form .col)  {
        margin: 3px 0;
        }
    }
    .action{
    margin-top: 30px;
}
}
// thead .el-table-column--selection .cell{
//     display: none;
// }
.shu_dafen{
  height: 30px;
  outline: #409eff;
  padding-left: 10px;
  border: #c0c4cc 1px solid;
}
</style>
